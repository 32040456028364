<template>
	<div>
		<!-- <v-btn
			@click="sendMail()"
		>
			enviar
		</v-btn> -->
		<div>
			<v-row>
				<v-col cols="3" md="3" >
					<v-card >
						<v-card-title class="grey " style="padding: 1%;">
							<span class="" style="color: #f1f1f1;margin-left: 15PX;">Proyectos</span>
							<v-btn
								icon
								@click="opendialogResult"
								color="white"
							>
								<v-icon>mdi-chart-bar</v-icon>
							</v-btn>
							<v-btn
								icon
								@click="opendialogTaskProceso"
								color="white"
							>
								<v-icon>mdi-circle-edit-outline</v-icon>
							</v-btn>
						</v-card-title>
						<v-list   
							:lines="false"
							density="compact"
							nav
							dense
							class="scrollable">
							<v-list-item-group>
								<v-list-item v-for="proyecto in Proyectic" 
								:key="proyecto.TkpID" 
								@click="enviarProyecto(proyecto)"
								color="info">
									<v-list-item-icon>
										<v-icon>mdi-laptop</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title>{{ proyecto.TkpTitle }}</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
							</v-list-item-group>
						</v-list>
					</v-card>
				</v-col>
		        <v-col cols="9" md="9">
				<s-crud
					v-if="dialogTable"
					:title="proyect.TkpTitle"
					:config="config"
					:filter="filter"
					:add="TkpStatus == 3 ? false : true"
					:edit="TkpStatus == 3 ? false : true"
					:remove="TkpStatus == 3 ? false : true"
					@save="save($event)"
					ref="gridtask"
					@rowSelected="rowSelected($event)" 
					searchInput
				>
			  <template v-slot:filter>
				<v-container>
					<v-row>
						<!--<v-col>
							<s-date
								label="Fecha Inicio" 
								v-model="filter.DateBegin" 
							></s-date>
						</v-col>
						<v-col>
							<s-date
								label="Fecha Fin" 
								v-model="filter.DateEnd" 
							></s-date>
						</v-col>-->
						<v-col v-if="filter.IsAdmin == 1">
							<s-select
								label="Responsable"
								:items="workerstic"
								autocomplete
								clearable
								item-text="WorkerName"
								item-value="PrsID"
								v-model="filter.PrsID"
							></s-select>
						</v-col>
						<v-col>
							<s-select-definition
								label="Prioridad"
								:def="1392"
								v-model="filter.TskPriority"
								clearable
							></s-select-definition>
						</v-col>
						<v-col>
							<s-select-definition
								label="Estado"
								:def="1393"
								v-model="filter.TskStatus"
								clearable
							></s-select-definition>
						</v-col>
						<v-col cols="3">
							<s-select-definition
								label="Tipo Tarea"
								:def="1476"
								v-model="filter.TypeTask"
								clearable
							></s-select-definition>
						</v-col>
						<v-col>
							<s-select-definition
								label="Clasificación"
								:def="1528"
								v-model="filter.TypeClasification"
								clearable
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			  </template>

			  <template v-slot:AsgWorkerName="{ row }">
				<v-btn v-if="$fun.isAdmin()" x-small color="primary" @click="AsgWorkerName(row)">
					<i class="fas fa-plus-circle"></i>
				</v-btn>
			</template>

			 <template scope="props">
				<v-container>
					<v-row justify="left">
						
						<v-col cols="12">
							<s-text
								label="Titulo"
								v-model="props.item.TskTitle"
							></s-text>
						</v-col>
						
						<v-col cols="12">
							<s-textarea
								label="Descripción"
								v-model="props.item.TskDescription"
							></s-textarea>
						</v-col>
						<v-col cols="3">
							<s-select-definition
								label="Prioridad"
								:def="1392"
								v-model="props.item.TskPriority"
							></s-select-definition>
						</v-col>
						<v-col cols="3">
							<s-select-definition
								label="Tipo Tarea"
								:def="1476"
								v-model="props.item.TypeTask"
							></s-select-definition>
						</v-col>
						<v-col cols="3">
							<s-select-definition
								label="Clasificación"
								:def="1528"
								v-model="props.item.TypeClasification"
							></s-select-definition>
						</v-col>
						<v-col lg="3" v-if="props.item.TypeClasification == 2">
							<v-file-input
								label="Anexo"
								v-model="Attach1"
								hide-details
							/>
						</v-col>
						<v-col cols="6" v-if="filter.IsAdmin == 1">
							<s-select
								label="Asignado a"
								:items="workerstic"
								autocomplete
								clearable
								v-model="props.item.PrsID"
								item-text="WorkerName"
								item-value="PrsID"
							></s-select>
						</v-col>
						<v-col cols="6">
							<s-select
								label="Objetivos"
								:items="targetTic"
								autocomplete
								clearable
								v-model="props.item.TpoID"
								item-text="TargetName"
								item-value="TpoID"
							></s-select>
						</v-col> 
					</v-row>
				</v-container>
			 </template>

			 <template v-slot:options>
				<!-- <v-tooltip bottom="" v-if="selected.TskStatus == 1 || selected.TskStatus == 2">
					<template v-slot:activator="{ on }">
						<v-btn text small style="margin-right:5px;" v-on="on" @click="pausedTask()"> 
							<v-icon style="font-size:18px;" v-if="selected.TskPaused == 0"> far fa-pause-circle</v-icon>
							<v-icon style="font-size:18px;" v-if="selected.TskPaused == 1"> far fa-play-circle</v-icon>
						</v-btn>
					</template>
					<span v-if="selected.TskPaused == 0">Pausar</span>
					<span v-if="selected.TskPaused == 1">Iniciar</span>
				</v-tooltip> -->

				<!-- <v-tooltip bottom="" v-if="selected.TskStatus == 1 || selected.TskStatus == 2">
					<template v-slot:activator="{ on }">
						<v-btn text small style="margin-right:5px;" v-on="on" @click="transferTask()"> <v-icon style="font-size:18px;"> fas fa-random</v-icon></v-btn>
					</template>
					<span>Transferir</span>
				</v-tooltip> -->
			 </template>

			 <template v-slot:FilePathDownload="{ row }">
				<!-- {{ row.FilePathName }} -->
				<v-btn v-if="row.FilePath != null && row.TypeClasification == 2" color="success" class="mt-5" :disabled=false icon x-small @click='openFile(row.FilePath)'> <v-icon>mdi-download</v-icon>  </v-btn>
			</template>

			 <template v-slot:Action="{ row }">
				<v-btn
					v-if="row.TskStatus == 1"
					x-small
					color="success"
					@click="clickActionTask(row)"
				>
					{{'Iniciar'}}
				</v-btn>
				<v-btn
					v-if="row.TskStatus == 2"
					x-small
					color="error"
					@click="clickActionTask(row)"
				>
					{{'Terminar'}}
				</v-btn>
			 </template>
			 <template v-slot:ConditButt="{ row }" v-if="$fun.isAdmin()">
				<v-btn
					x-small
					color="info"
					@click="clickConditionTask(row)"
				>
					{{'Condición'}}
				</v-btn>
			 </template>
			 <template v-slot:TskStatus="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.TskStatusColor"
				>
					{{ row.TskStatusName }}
				</v-chip>
			 </template>
             
			 <template v-slot:NameTypeTask="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.TypeTask ===1 ? '#abd5f5' : '#fdeb65'"
				>
					{{ row.NameTypeTask }}
				</v-chip>
			 </template>


			 <template v-slot:TskPriority="{ row }">
				<v-chip 
					x-small 
					black
					:color="row.TskPriorityColor"
				>
					{{ row.TskPriorityName }}
				</v-chip>
			 </template>
			 </s-crud>
			 </v-col>
		     </v-row>
		      </div>
			 <v-dialog
				v-model="dialogTransfer"
				width="500"
			 >
			 <v-card>
				<v-container>
					<v-row>
						<v-col>
							<s-select
								label="Transferir a"
								:items="workerstic"
								autocomplete
								clearable
								item-text="WorkerName"
								item-value="PrsID"
								v-model="datatransfer.PrsIDTraslate"
							></s-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<s-textarea
								label="Motivo"
								v-model="datatransfer.TskReasonTraslate"
							></s-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn
								color="primary"
								@click="sendTransfer()"
							>
								{{"Transferir"}}
							</v-btn>
						</v-col>
						<v-col>
							<v-btn
								color="warning"
								@click="cancelTransferTask()"
							>
								{{"Cancelar"}}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
					</v-card>
				</v-dialog>

				<v-dialog
					v-model="dialogPaused"
					width="500"
				>
			 <v-card>
				<v-container>
					<v-row>
						<v-col>
							<s-textarea
								label="Motivo"
								v-model="dataPaused.TskReasonTraslate"
							></s-textarea>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn
								color="primary"
								@click="sendPaused()"
							>
								{{selected.TskPaused == 0 ? "Pausar" : "Reanudar"}}
							</v-btn>
						</v-col>
						<v-col>
							<v-btn
								color="warning"
								@click="cancelPausedTask()"
							>
								{{"Cancelar"}}
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
					</v-card>
				</v-dialog>
				<!-- Modal (v-dialog) -->
				<v-dialog
				v-model="dialogResult" 
				max-width="600px" persistent
				>
				<v-card>
					<!-- <v-card-title class="result-title">
					RESUMEN POR SEMANA
					</v-card-title> -->
					<s-toolbar label="RESUMEN POR SEMANA" dark color="#8e8f91"  excel @excel="downloadExcel()"></s-toolbar>
					<v-card-text>
				<v-col style="padding-top:0px;">
                        <v-row justify="center">
							<v-col lg="3"  style="padding-bottom:0px">
                                <s-select label="Año" :items="years" outlined hide-details dense v-model="year"></s-select>
                            </v-col>
                            <v-col lg="3" style="padding-bottom:0px">
                                <!--  <s-select label="Periodo" dense hide-details outlined :items="itemsWeek" v-model="week"></s-select>-->
                                <s-select small-chips attach="" multiple label="N° Semana" dense hide-details outlined :items="itemsWeek"  v-model="week"></s-select>
                            </v-col>
                            <v-col lg=3>
                            <s-date label="Fecha Inicio" v-model='BeginDate'
                            />  </v-col>
                            <v-col lg=3> 
                            <s-date label="Fecha Fin" v-model='EndDate'
                        >
                              
                            </s-date>  </v-col>
                        </v-row>
                </v-col>
				 <!-- Fila de cabecera adicional -->
				 <v-row>
                <v-col v-for="(item, index) in headerRow" :key="index" class="text-center" style="margin-top: 10px; background-color: #e7eef1;">
                    <span >{{ item }}</span>
                </v-col>
            </v-row>

            <!-- Tabla de datos -->
            <v-row>
                <v-col class="pl-0 pt-0 pb-0 pr-0">
                    <v-data-table
                        :headers="headerProductionGroup"
                        :items="summarytic"
                        hide-default-footer
                        disable-sort
						:loading="loadingData"
                    >
					<template v-slot:item.ProgramadaTo="{ item }"> 
						<v-chip
							small
							dark 
							:color="'success'"
						>
							{{ item.ProgramadaTo }}
						</v-chip>
					</template>
					<template v-slot:item.NoProgramadaTo="{ item }"> 
						<v-chip
							small
							dark 
							:color="'info'"
						>
							{{ item.NoProgramadaTo }}
						</v-chip>
					</template>
                    </v-data-table>
                </v-col>
            </v-row>
			</v-card-text>
			<v-card-actions>
			<v-btn color="primary" @click="dialogResult = false; week=null;">Cerrar</v-btn>
			</v-card-actions>
		 </v-card>
		 </v-dialog>
		 <!---->
		 <v-dialog v-model="dialogProccess" max-width="800px" persistent>
  <v-card>
    <v-card-title class="result-title">
      TAREAS ACTIVAS EN PROCESO
    </v-card-title>
    <v-card-text>
      <!-- Table for displaying tictaStatusList -->
      <v-simple-table dense>
        <thead>
          <tr>
            <th>Id</th>
            <th>Título</th>
            <th>Descripción</th>
            <th>Responsable</th>
            <th>Proyecto</th>
            <th>Fecha Creación</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="task in tictaStatusList" :key="task.TskID">
            <td>{{ task.TskID }}</td>
            <td>{{ task.TskTitle }}</td>
            <td>{{ task.TskDescription }}</td>
            <td>{{ task.WorkerName }}</td>
            <td>{{ task.TkpTitle }}</td>
            <td>{{ $fun.formatDateTimeView(task.TskDate) }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
    <v-card-actions>
      <v-btn color="primary" @click="dialogProccess = false; week=null;">Cerrar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

	<!--condition tabla-->
	<v-dialog v-model="modalCondition" max-width="600px" ref="tblCondicion">
  <v-card>
    <v-card-title class="headline primary" style="color: white;">
      Seleccionar condiciones:
      <v-btn text color="white" @click="FunAdd()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-subtitle class="pa-5">
      <div v-if="showInputField" class="mt-1 bordered-div">
        <v-row>
          <v-col>
            <s-text class="s-col-form" placeholder="Nueva condición" v-model="newConditionName"></s-text>
          </v-col>
          <v-col>
            <v-btn color="primary" @click="registrarCondicion">Registrar</v-btn>
          </v-col>
        </v-row>
      </div>

      <!-- Filtro de búsqueda -->
      <s-text class="pa-1" placeholder="Buscar por condición" append-icon="mdi-magnify" search-icon v-model="filterText"></s-text>

      <!-- Tabla para mostrar las condiciones -->
      <v-simple-table dense class="pa-1">
        <thead>
          <tr>
            <th></th>
            <th>Condiciones</th>
			<th>Accion</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="condition in filteredConditions" :key="condition.TskID"  >
            <td style="width: 50px;">
              <v-checkbox
                v-model="condition.selected"
                :value="condition.selected"
                :id="String(condition.TkcID)"
                hide-details
                class="ma-0"
              ></v-checkbox>
            </td>
            <td class="pa-0" @click="selectCondition(condition)" >{{ condition.TkcName }}</td>
			<td class="pa-0"><v-btn text color="grey" @click="FunctDelete(condition)"><v-icon>mdi-delete</v-icon></v-btn></td>
          </tr>
        </tbody>
      </v-simple-table>

      <!-- Paginador -->
      <v-pagination v-model="page" :length="totalPages" :total-visible="5" class="mt-4"></v-pagination>

      <!-- Barra de progreso con el porcentaje -->
      <v-progress-linear
        :value="progressPercentage"
        height="20"
        color="blue"
        class="mt-4"
        striped
      >
        <!-- Mostrar el porcentaje dentro de la barra -->
        <template v-slot:default>
          <div class="text-center" style="width: 100%; position: absolute; top: 50%; transform: translateY(-50%);color:white;">
            {{ progressPercentage.toFixed(2) }}%
          </div>
        </template>
      </v-progress-linear>
    </v-card-subtitle>

    <v-card-actions>
      <v-btn text color="error" @click="CerrarModalCondition()">Cerrar</v-btn>
      <v-btn text color="primary" @click="guardarSeleccion">Guardar</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

		<v-dialog
			v-model="dialogUsers"
			width="500"
			persistent
		>
		<v-card>
			<s-toolbar label="Usuarios" dark color="#8e8f91" save @save="saveUsers()" @add="addNewUsers" add :remove="this.selectedSearch.length > 0" @removed="AsgDeleteSchedule()" close @close="dialogUsers=false"></s-toolbar>
            <v-container>
                <v-row>
                    <v-col>
                        <v-data-table
						height="300"
                            item-key="PrsID"
                            dense
                            fixed-header=""
                            :items="filtereditemsSearch"
                            :headers="headersSearch"
                            :items-per-page="10"
                            @click:row="rowClickSearch"
                            v-model="selectedSearch"
                        ></v-data-table>
                    </v-col>
                </v-row>
            </v-container>
		</v-card>
		</v-dialog>

		<v-dialog
			v-model="dialogUsersAdd"
			width="500"
			persistent
		>
		<v-card>
			<v-container style="padding: 5px 15px 5px 5px;">
				<s-user v-model="usersCopy" ref="usrcopy" :isCrud="false" :singleRow="false" />
				<v-row>
					<v-col>
						<v-btn color="red" dark depressed small @click="dialogUsersAdd=false">Cancelar</v-btn>
					</v-col>
					<v-col align="right">
						<v-btn color="success" dark depressed small @click="saveUserPermit()">Asignar Usuarios</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
		</v-dialog>

	     </div>
</template>
<style scoped>
.result-title {
  background-color: #3f51b5; 
  color: white;            
  padding: 16px 24px;        
  font-weight: bold;        
  border-bottom: 2px solid #fff; 
  border-radius: 4px 4px 0 0;
  margin-bottom: 15px;
}
</style>
<script>
	import _sTaskService from '@/services/InformationTechnology/TaskService.js';
	import sTextarea from '../../../components/Utils/sTextarea.vue';
	import { isUndefined } from 'lodash';
	import _sGeneral from "@/services/HelperService.js";
	import _sHelper from "@/services/HelperService";
	import sUser from "@/views/Security/UserOption/User.vue";

	export default {
  components: { sTextarea, sUser },
		data() {
			return {
				loadingData: false,
				usersCopy: [],
				dialogUsers: false,
				dialogUsersAdd: false,
				Attach1: null,
				dialogTable: true,
				TkpStatus: null,
				TkcID: 0,
				proyect: {},
				datatransfer: {},
				dataPaused: {},
				dialogTransfer: false,
				dialogPaused: false,
				dialogResult: false,
				selected : {},
				workerstic: [],
				targetTic:[],
				ConditionTic:[],
				summarytic:[],
				Gensummarytic:[],
				Proyectic:[],
				itemsWeek: [],
				years: [],
				dialogProccess:false,
				TksIDi:0,
				modalCondition:false,
				year: null,
				week: null,
				month:null,
				SecStatus:1,
				year:null,
				BeginDate:null,
				EndDate:null,
				tictaStatusList:null,
				filter: {
					PrsID: 0,
					IsAdmin: this.$fun.isAdmin() ? 1 : 0	,
					TkpID:0,
					TypeClasification : null 
				},
				page: 1, // Página actual
				perPage: 10, // Elementos por página
				filterText: '', // Texto de filtro
				showInputField:false,
				newConditionName:'',

				headersSearch: [
					{text: "ID"									, value: "PrsID"},
					{text: "Apellidos y Nombres"				, value: "NtpFullName"}
				],
				itemsSearch: [],
				selectedSearch: [],

				config: {
					model: {
						TskID: "ID",
						Action: "Action",
						ConditButt: "ConditButt",
						TskStatus: "integer",
						TskPriority: "integer",
						NameTypeTask: "integer",
						FilePathDownload: "FilePathDownload",
						AsgWorkerName: ""
					},
					service: _sTaskService,
					headers: [
						{text: "ID", value: "TskID"},
						{text: "Acción", value: "Action"},
						{text: "Condición", value: "ConditButt"},
						{text: "Titulo", value: "TskTitle"},
						{text: "Tipo", value: "TypeClasificationName"},
						{text: "Archivo", value: "FilePathDownload"},
						{text: "Objetivo", value: "TargetName"},
						{text: "Prioridad", value: "TskPriority"},
						{text: "Abierto", value: "TskDateName"},
						{text: "Asignado a", value: "AsgWorkerName"},
						{text: "Tipo Tarea", value: "NameTypeTask"},
						// {text: "F. Ini", value: "TskDateBeginName"},
						// {text: "F. Fin", value: "TskDateEndName"},
						{text: "Estado", value: "TskStatus"},
					]
				},
			headerRow: ['', 'PROGRAMADO', 'NO PROGRAMADO'],
            headerProductionGroup: [
            	{ text: "Nombre", value: "WorkerName" }, 
				{ text: "Total", value: "ProgramadaTo" },
				{ text: "Pendiente", value: "ProgramadaPen" },
				{ text: "Finalizado", value: "ProgramadaFin" }, 
				{ text: "Total", value: "NoProgramadaTo" , headerClass: 'success', },
				{ text: "Pendiente", value: "NoProgramadaPen" , headerClass: 'success', },
				{ text: "Finalizado", value: "NoProgramadaFin" , headerClass: 'success', }

            ],
			itemSelectedSave: {}
			}
		},
		computed: {
			filtereditemsSearch() {
        	return this.itemsSearch.filter(item => item.SecStatus !== 0);
        },
		filteredConditions() {
			// Filtramos las condiciones según el texto de búsqueda
			const filtered = this.ConditionTic.filter(condition =>
			condition.TkcName.toLowerCase().includes(this.filterText.toLowerCase())
			);
			return filtered.slice((this.page - 1) * this.perPage, this.page * this.perPage);
		},
		totalPages() {
			// Calculamos cuántas páginas serán necesarias
			const filteredCount = this.ConditionTic.filter(condition =>
			condition.TkcName.toLowerCase().includes(this.filterText.toLowerCase())
			).length;
			return Math.ceil(filteredCount / this.perPage);
		},
		progressPercentage() {
			// Filtramos las condiciones visibles
			const totalVisibleConditions = this.filteredConditions.length;
			const selectedConditions = this.filteredConditions.filter(condition => condition.selected).length;

			// Si no hay condiciones visibles, no hay progreso
			if (totalVisibleConditions === 0) return 0;

			// Calculamos el porcentaje
			return (selectedConditions / totalVisibleConditions) * 100;
		},
		},
		watch : {
			filter(){
				// console.log(this.filter)
			},
			week(){
				if (this.week == 1) {

				this.BeginDate = this.$moment(this.year+'0101').isoWeek(this.itemsWeek[0]).startOf("isoweek").format('YYYY-MM-DD');
				this.EndDate = this.$moment(this.year+'0101').isoWeek(this.itemsWeek[0]).endOf("isoweek").format('YYYY-MM-DD');

				if(this.year!=this.$moment(this.year+'0101').isoWeek(this.itemsWeek[0]).startOf("isoweek").format('YYYY')){
					this.BeginDate=this.$moment(this.year+'0108').isoWeek(this.itemsWeek[0]).startOf("isoweek").format('YYYY-MM-DD');
					this.EndDate = this.$moment(this.year+'0108').isoWeek(this.itemsWeek[0]).endOf("isoweek").format('YYYY-MM-DD');

				}
				} 
				else if (this.week > 1) {
				// console.log("this.itemsWeek[this.itemsWeek.length - 1]", this.itemsWeek[this.itemsWeek.length - 1])
				// console.log("this.itemsWeek[0]", this.itemsWeek[0])
				if (this.itemsWeek[this.itemsWeek.length - 1].value > this.itemsWeek[0].value) {
					
					// console.log("1 if")
					// let min = Math.min(...this.itemsWeek);
					let min = this.week;
				// console.log("  min[0]", min)

					this.BeginDate = this.$moment(this.year+'0101').isoWeek(min).startOf("isoweek").format('YYYY-MM-DD');
					this.EndDate = this.$moment(this.year+'0101').isoWeek(min).endOf("isoweek").format('YYYY-MM-DD');
				
				} else {
					// console.log("2 if")
					let max = Math.max(...this.itemsWeek);

					this.BeginDate = this.$moment(this.year+'0101').isoWeek(this.itemsWeek[this.itemsWeek.length - 1]).startOf("isoweek").format('YYYY-MM-DD');
					this.EndDate = this.$moment(this.year+'0101').isoWeek(max).endOf("isoweek").format('YYYY-MM-DD');
						
					if(this.year!=this.BeginDate.year){
						this.BeginDate=this.$moment(this.year+'0108').isoWeek(this.itemsWeek[this.itemsWeek.length - 1]).startOf("isoweek").format('YYYY-MM-DD');
						this.EndDate = this.$moment(this.year+'0108').isoWeek(max).endOf("isoweek").format('YYYY-MM-DD');

					}
				}

				if (this.week.length > 1) {

					if ((this.week.find((x) => { return x == (this.week[this.week.length - 1] - 1) }) == null) &&
						(this.week.find((x) => { return x == (this.week[this.week.length - 1] + 1) }) == null)) {
						this.week = this.week.filter((x) => { return x != this.week[this.week.length - 1] })
					}
				}
				}
				else{
				this.EndDate = this.$moment().add('days', -1).format("YYYY-MM-DD");
				this.BeginDate = this.$moment().format("YYYY-MM-DD");
				}
				this.getTicSummary();
				this.getTicGeneralSummary();
			},
			BeginDate(){
				this.getTicSummary();
				this.getTicGeneralSummary();
			},
			EndDate(){
				this.getTicSummary();
				this.getTicGeneralSummary();
			},
		},
		methods: {
			selectCondition(condition) {
				console.log("selectcondi",condition);
				this.TkcID=condition.TkcID;
				this.showInputField = true;
				this.newConditionName=condition.TkcName ;
			},
			FunctDelete(condition){
				this.TkcID=condition.TkcID;
				this.newConditionName=condition.TkcName ;
				this.SecStatus = 0;
				this.registrarCondicion();
			},
			AsgDeleteSchedule(){
				// console.log("this.selectedSearch:",this.selectedSearch);
				if(this.selectedSearch[0].TtuID == null){
					this.itemsSearch.splice(this.itemsSearch.indexOf(this.selectedSearch[0]), 1);
				}else{
					// console.log("this.selectedSearchRemove:",this.selectedSearch[0]);
					const index = this.itemsSearch.indexOf(this.selectedSearch[0]);
					if (index !== -1) {
						this.itemsSearch[index].SecStatus = 0;
					}
				}

				this.selectedSearch = [];
			},
			saveUsers(){
				// console.log("saveUsers:",this.itemsSearch);
				this.itemSelectedSave.itemsDetail = this.itemsSearch;
				this.itemSelectedSave.UsrUpdateID = this.$fun.getUserID();
				// console.log("itemSelectedSave:",this.itemSelectedSave);
				_sTaskService.save(this.itemSelectedSave, this.$fun.getUserID())
				.then(resp => {
					// console.log("resp:",resp);
					if(resp.status == 200){
						this.$fun.alert("Actualizado correctamente", "success");
						this.$refs.gridtask.refresh();
						this.dialogUsers = false;
					}
				})
			},
			saveUserPermit(){
				this.usersCopy = Object.assign([], this.usersCopy).filter((x) => x.UsrID != "");
				// console.log("this.usersCopy:",this.usersCopy);
				// console.log("this.itemsSearch:",this.itemsSearch);
				this.dialogUsersAdd = false;
				if(this.usersCopy.length>0){
					for (let i = 0; i < this.usersCopy.length; i++) {
						const prsID = this.usersCopy[i].PrsID;
						
						const exists = this.itemsSearch.some(item => item.PrsID == prsID);
						// console.log("exists:",exists);
						if (!exists) {
							const itemNew = {
								PrsID: prsID,
								UsrCreateID: this.$fun.getUserID(),
								NtpFullName: this.usersCopy[i].NtpFullName
							};
							this.itemsSearch.push(itemNew);
						}
					}
				}

				if(this.itemsSearch.length>0){
					for (var i = 0; i < this.itemsSearch.length; i++) {
						this.itemsSearch[i].UsrCreateID = this.$fun.getUserID();
					}
				}
				// console.log("this.itemsSearch:", this.itemsSearch);
			},
			addNewUsers(){
				this.dialogUsersAdd = true;
				this.usersCopy = [];
				// this.$refs.usrcopy.refresh();
			},
			AsgWorkerName(row){
				console.log("AsgWorkerName:",row);
				this.itemSelectedSave = row;
				if(row.itemsDetail != null){
					row = JSON.parse(row.itemsDetail);
					this.itemsSearch = row;	
				}else{
					this.itemsSearch = [];
				}
				this.dialogUsers = true;
			},
			rowClickSearch: function(item, row) {
				// console.log("item Desde la tabla:",item);
				if (item !== undefined) {
					if(item == this.selectedSearch[0]){
						this.selectedSearch = [];
					}else{
						this.selectedSearch = [item];
					}
				}
			},
			enviarProyecto( proyect ){
				this.TkpStatus = proyect.TkpStatus;
				// console.log("this.TkpStatus", this.TkpStatus);
				this.proyect = proyect;
			  	this.filter.TkpID = proyect.TkpID;
			  	console.log(this.filter.TkpID);
				this.getTicTarget();
			},
			sendMail(task)
			{
				_sTaskService
				.sendmail(task, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.$fun.alert("Enviado correctamente", "success");
						return;
					}
				})
			},

			initialize()
			{
				this.getTicWorkers();
				this.getTicProyects();

			},

			async save(item) {
				// console.log("item save", item.PrsID);
				
				if (isUndefined(item.PrsID)) item.PrsID = this.filter.PrsID;
				item.AreID = this.filter.AreID;
				item.TkpID = this.filter.TkpID;
				if (item.SecStatus == null) item.SecStatus = 1;

				// console.log("item save", item);

				item.UsrCreateID = this.$fun.getUserID();
				item.FilePath = this.Attach1 != null ? this.Attach1.name : "";

				item.itemsDetail = [
					{
						UsrCreateID: this.$fun.getUserID(),
						PrsID: item.PrsID
					}
				];

				if(item.FilePath){
					// Crea un FormData para subir los archivos
					let files = [];
					if (this.Attach1) files.push(this.Attach1);
					var formData = new FormData();
					let i = 0;
					files.forEach((element) => {
						formData.append(i.toString(), element);
						i++;
					});
						// Primero sube los archivos
						_sGeneral.uploadfile("TIAnexosPrueba", this.$fun.getUserID(), formData, 3)
							.then(response => {
								if (response.status === 200) {
									// console.log("response", response);
								}
							})
				}

				try {
					await new Promise((resolve, reject) => {
						if (typeof item.save === "function") {
							const result = item.save();
							if (result instanceof Promise) {
								result.then(resolve).catch(reject);
							} else {
								resolve(result);
							}
						} else {
							reject(new Error("item.save no es una función"));
						}
					});
					// console.log("saveeeeeeee");
				} catch (error) {
					console.error("Error al guardar el item:", error);
				}

				this.dialogTable = false;
				setTimeout(() => {
					this.dialogTable = true;
				}, 10);
			},
			openFile(nameFile) {
				_sGeneral.download(nameFile, this.$fun.getUserID()).then((r) => {
					if (r.status === 200) {
						// console.log("Imprime :", r);
						this.$fun.downloadHttp(r);
					}       
				});
			},
			FunAdd() {
			this.TkcID=0;
			this.SecStatus = 1;
			this.showInputField = !this.showInputField;
			},

			// Función para registrar la nueva condición
			registrarCondicion() {
			if (this.newConditionName) {
				// Aquí deberías agregar la lógica para guardar la nueva condición
				// console.log("Nueva condición registrada: ", this.newConditionName);
				
				let obj = {
					TkcID:this.TkcID,
					TskID:this.TksIDi,
					TkcName: this.newConditionName,
					SecStatus: this.SecStatus,
					UsrCreateID: this.$fun.getUserID(),
					TkcStatus: 0
				}
				_sTaskService.saveCondition(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Accion realizada con exito", "success");
						this.showInputField = !this.showInputField;
						this.newConditionName='';
						this.getTicCondition(this.TksIDi);
					}
				});
			
			}
			},
			getTicWorkers()
			{
				let obj = {
					AreID: this.filter.AreID
				}
				_sTaskService
				.ticworkers(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						// console.log('work', resp.data)
						this.workerstic = resp.data;
					}
				})
			},
			getTicTarget()
			{
				let obj = {
					TkpID: this.filter.TkpID
				}
				_sTaskService
				.ticTargetbyProy(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						// console.log('target', resp.data)
						this.targetTic = resp.data;
					}
				})
			},
			CerrarModalCondition(){
				this.showInputField =false;
				this.modalCondition=false;
				this.page=1;
			},
			getTicCondition(TskID)
			{
				let obj = {
					TskID: TskID
				}
				_sTaskService
				.ticConditionByTask(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						// console.log('condiiton', resp.data)
						this.ConditionTic = resp.data;
						this.ConditionTic.forEach(condition => {
						condition.selected = condition.TkcStatus >0?true:false; // Si TkcID es 1, está seleccionado
						// console.log(condition.TkcStatus);
						});
					}
					
				});
			},
			getTictaskStaList()
			{
				let obj = {
					TskStatus: 2
				}
				_sTaskService
				.tictaskBystatusTask(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.tictaStatusList = resp.data;
						// console.log("tictaStatusList",this.tictaStatusList);
					}
				});
			},
            getTicSummary()
			{
				this.loadingData = true;
				let obj = {
					AreID: this.filter.AreID,
					DateBegin:this.BeginDate,
					DateEnd:this.EndDate
				}
				_sTaskService
				.ticketSummary(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						// console.log('sum', resp.data)
						this.summarytic = resp.data;
					}
					this.loadingData = false;
				})
			},
			getTicGeneralSummary()
			{
				let obj = {
					AreID: this.filter.AreID,
					DateBegin:this.BeginDate,
					DateEnd:this.EndDate
				}
				_sTaskService
				.ticketGeneralSummary(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						// console.log('sum', resp.data)
						this.Gensummarytic = resp.data;
					}
				})
			},
			downloadExcel(){
				console.log("Dataaaaaaaaaaaaaaa:",this.Gensummarytic);
				this.$fun.alert("¿Seguro de descargar?", "question")
				.then(r => {
					if(r.value){
						_sTaskService
						.convertexcel(this.Gensummarytic, this.$fun.getUserID())
						.then(resp => {
							this.$fun.downloadFile(resp.data, this.$const.TypeFile.EXCEL, this.$fun.getUserID());
						})
					}
				})
			},
            getTicProyects()
			{
				let obj = {
					AreID: this.filter.AreID,
					UsrID: this.$fun.getUserID(),
				}
				_sTaskService
				.ticproyect(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						// console.log('proye', resp.data)
						this.Proyectic = resp.data;
					}
				});
			},
			guardarSeleccion() {
				// Función para guardar las selecciones
				const selectedConditions = this.ConditionTic.filter(condition => condition.selected);
				const sconcatenatedString = selectedConditions.map(condition => condition.TkcID).join(", ");
				// console.log("aqui",sconcatenatedString); 
				const unselectedConditions = this.ConditionTic.filter(condition => !condition.selected);
				const uconcatenatedString = unselectedConditions.map(condition => condition.TkcID).join(", ");
				// console.log("Condiciones no seleccionadas:", uconcatenatedString);

				let obj = {
					TskID: this.TksIDi,
					SecStatus:1,
					UsrCreateID: this.$fun.getUserID(),
					TkcIDT: sconcatenatedString,
					TkcIDF: uconcatenatedString
				}
				_sTaskService
				.TicTaskConditionTask(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200){
						this.$fun.alert("Actualizado correctamente", "success");
						this.modalCondition=false;
					}
				});
			},
            clickConditionTask(item){
				this.modalCondition=true;
				this.TksIDi=item.TskID;
               this.getTicCondition(item.TskID);
			},

			clickActionTask(item)
			{
				if(this.selected.TskPaused == 1)
				{
					this.$fun.alert("La tarea se encuentra pausada", "info");
					return;
				}

				let message = ''
				if (item.TskStatus == 1)
				{
					message = '¿Seguro de iniciar?'
					item.TskStatus = 2;
					item.SecStatus = 1;
					item.TskDateBegin='1999-09-09 10:00:50.747';
				}
				else
				{
					message = '¿Seguro de finalizar?'
					item.TskStatus = 3;
					item.SecStatus = 1
					item.TskDateEnd='1999-09-09 10:00:50.747';
				}

				this.$fun.alert(message, "question")
				.then(r => {
					if(r.value)
					{
						_sTaskService
						.save(item, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								if(item.TskStatus = 2){
									this.sendMail(item);
								}
								this.$fun.alert("Actualizado correctamente", "success");
								this.$refs.gridtask.refresh();  
							}
						})
					}
				})
				
			},
            opendialogResult(){
             this.dialogResult = true;
			},
			opendialogTaskProceso(){
             this.dialogProccess = true; 
			 this.getTictaskStaList();
			},
			transferTask()
			{
				// console.log('estado ', this.selected.TskStatus);
				if(this.selected.TskStatus != 1 && this.selected.TskStatus != 2)
				{
					this.$fun.alert("La tarea ya fue finalizada o transferida", "info");
					return;
				}
				this.dialogTransfer = true;
			},

			pausedTask()
			{
				if(this.selected.TskStatus != 1 && this.selected.TskStatus != 2)
				{
					this.$fun.alert("La tarea ya fue finalizada o transferida", "info");
					return;
				}
				if(this.selected.TskPaused == 1)
				{
					this.$fun.alert("¿Seguro de reanudar tarea?", "question")
					.then(r => {
						if(r.value)
						{
							this.selected.TskStatus = 104;
							this.selected.UsrUpdateID = this.$fun.getUserID();
							_sTaskService
							.save(this.selected, this.$fun.getUserID())
							.then(resp => {
								if(resp.status == 200)
								{
									this.$fun.alert("Reanudada correctamente", "success");
									this.$refs.gridtask.refresh();
									this.dialogPaused = false;
									return;
								}
							})
						}
					})
					return;
				}
				if(this.selected.TskPaused == 0)
				{
					this.dialogPaused = true;
				}
				
			},

			cancelPausedTask()
			{
				this.dialogPaused = false;
			},
			restartFilter() {
				this.month = null;
				this.week = [];
				this.EndDate = this.$moment().add('days', -1).format("YYYY-MM-DD");
				this.BeginDate = this.$moment().format("YYYY-MM-DD");
            },

			sendPaused()
			{

				this.selected.TskStatus = 103;
				this.selected.UsrUpdateID = this.$fun.getUserID();
				this.selected.TskReasonTraslate = this.dataPaused.TskReasonTraslate;
				this.$fun.alert("¿Seguro de pausar?", "question")
				.then(r => {
					if(r.value)
					{
						_sTaskService
						.save(this.selected, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Pausado correctamente", "success");
								this.$refs.gridtask.refresh();
								this.dialogPaused = false;
								return;
							}
						})
					}
				})
			},
            
			cancelTransferTask()
			{
				this.dialogTransfer = false;
			},

			sendTransfer()
			{
				if(this.selected.PrsID == this.datatransfer.PrsIDTraslate)
				{
					this.$fun.alert("No se puede trasladar a la misma persona", "info");
					return;
				}
				this.$fun.alert("¿Seguro de transferir?", "question")
				.then(r => {
					if(r.value)
					{
						this.selected.PrsIDTraslate = this.datatransfer.PrsIDTraslate;
						this.selected.TskReasonTraslate = this.datatransfer.TskReasonTraslate;
						this.selected.UsrUpdateID = this.$fun.getUserID();
						this.selected.TskStatus = 102
						
						_sTaskService
						.save(this.selected, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Transferido correctamente", "success");
								this.$refs.gridtask.refresh();
								this.dialogTransfer = false;
							}
						})
					}
				})
				
			},

			rowSelected(items)
			{
				// console.log(items);
				if(items.length > 0)
				{
					this.selected = items[0];
				}
			}
		},

		created () {
			this.filter.AreID =  this.$fun.getUserInfo().CtrContract.AreID ?? 0;
			for (let i = 1; i <= 53; i++) {
            this.itemsWeek.push({
                text: i,
                value: i,
            });
            }
			this.years = this.$fun.getTop5LastYears();
			this.year = this.$moment().year();
			this.initialize();
		},
		mounted() {
			
			this.filter.PrsID =  this.$fun.getUserInfo().PrsID; 
					
			// console.log( "USER INFO",this.$fun.getUserInfo()); 
		}
	}
</script>

<style>
	.scrollable {
	max-height: 450px; /* Ajusta según sea necesario */
	overflow-y: auto;  /* Habilita el scroll vertical */
	}
	.bg-green{
		background-color: green;
	}
	.bordered-div {
	border: 1px solid grey; /* Borde negro de 2px */
	padding: 10px; /* Espaciado dentro del div */
	margin: 5px;
	border-radius: 5px; /* Esquinas redondeadas opcionales */
	}

</style>